import * as m from "mithril";
import {Screen, Section} from "../components";

interface Attrs {}
interface State {}

type Vnode = m.Vnode<Attrs, State>;

export const RedirectingScreen: m.Component<Attrs, State> = {
  view: (vnode: Vnode) => {
    return m(Screen, [
      m(Section, {selector: ".align-center", contentModifiers: ["small"]}, [
        m("h1.margin-none", "Redirecting..."),
      ]),
    ]);
  },
};

