import * as m from "mithril";
import {
  actionProgress,
  actionProgressColor,
  actionsForSectionWithQuestion,
} from "../Actions";
import {FormState} from "../FormState";
import {capitalise} from "../Language";
import {Location} from "../Location";
import * as Overlay from "../Overlay";
import {
  PublishedForm,
  PublishedFormAction,
  PublishedFormSection,
} from "../PublishedForm";
import {block} from "../Selector";
import {Button} from "./Button";
import {FormSectionOverlay} from "./FormSectionOverlay";
import {FormQuestionOverlay} from "./FormQuestionOverlay";
import {PercentageCircle} from "./PercentageCircle";
import {PublicFormSubmissionAPI} from "../api";
import {log} from "../Log";

interface Attrs {
  section: PublishedFormSection;
  formState: FormState;
  publishedForm: PublishedForm;
  locations: Location[];
}

interface State {
  location: Location | null;
}

type Vnode = m.Vnode<Attrs>;

export const DashboardSectionCard: m.Component<Attrs> = {
  onupdate: (vnode: Vnode) => {
    const locationID = vnode.attrs.formState.detail("location_id");
    vnode.state.location =
      vnode.attrs.locations.find((location) => {
        return location.id() === locationID;
      });
  },

  view: (vnode: Vnode) => {
    const {attrs: {section, formState, publishedForm}} = vnode;
    const isExcluded = formState.isExcludedSection(section);
    const actionsWithQuestion =
      actionsForSectionWithQuestion(formState, section);
    const actions = actionsWithQuestion.map(({action}) => action);
    const progress = !isExcluded ? actionProgress(actions) : 0;
    const color = actionProgressColor(progress);
    return m(".dashboard-section-card", [
      m("h3.dashboard-section-card__title", section.name()),
      m(".dashboard-section-card__icon", {
        style: `background-image: url('${section.iconURL("purple")}');`,
      }, [
        m(PercentageCircle, {
          percentage: progress,
          color: progress === 0 ? "transparent" : color,
        }),
      ]),
      !isExcluded && actionsWithQuestion.map(({action, question}) => {
        const modifiers = action.markAsDone() ? ["active"] : [];
        return m(".dashboard-section-card__action", {
          onclick: () => {
            Overlay.open(FormQuestionOverlay, {formState, section, question});
          },
        }, [
          m(block("dashboard-section-card__action__checkbox", modifiers)),
          m(".dashboard-section-card__action__text", [
            capitalise(action.text()),
          ]),
        ]);
      }),
      m(".dashboard-section-card__button-container", [
        !isExcluded && vnode.state.location && m("a", {
          style: "width: 100%",
          target: "_blank",
          href: buildAdvisorURL(vnode),
          onclick: () => addAdvisorSearchedSection(section),
        }, [
          m(Button, {
            selector: ".margin-top-small",
            modifiers: "full-width",
          }, "Find a local advisor"),
        ]),
        isExcluded && m(Button, {
          selector: ".margin-top-small",
          modifiers: ["outline", "full-width"],
          onClick: () => {
            formState.addFilteredSection(section);
            formState.changeCurrentSectionID(section.id());
            Overlay.open(FormSectionOverlay, {
              formState,
              publishedForm,
              section,
            });
          },
        }, "Get started"),
      ]),
    ]);
  },
};

function buildAdvisorURL(
  {attrs: {section, formState, publishedForm}, state: {location}}: Vnode,
) {
  const locationKey = encodeURIComponent("search_organizations[location_id]");
  const params = [`${locationKey}=${location.basnetRelatedID()}`];
  if (section.serviceType()) {
    const serviceTypesKey =
      encodeURIComponent("search_organizations[service_types][]");
    const namedID = section.serviceType().basnetRelatedID();
    params.push(`${serviceTypesKey}=${namedID}`);
  }
  return "https://businesslinkpacific.com/search?" +
    params.join("&");
}

function addAdvisorSearchedSection(section: PublishedFormSection) {
  PublicFormSubmissionAPI.addAdvisorSearchedSection(section.id());
}
