import * as m from "mithril";
import {block} from "../Selector";
import {Footer} from "./Footer";
import {Header} from "./Header";
import {MobileNavPanel} from "./MobileNavPanel";

interface Attrs {
  selector?: string;
  modifiers?: string | string[];
  noFooter?: boolean;
}

interface State {
  navOpen?: boolean;
}

type Vnode = m.Vnode<Attrs>;

export const Screen: m.Component<Attrs> = {
  oncreate: (vnode: Vnode) => {
    window.scrollTo(0, 0);
  },

  view: (vnode: Vnode) => {
    const selector = vnode.attrs.selector || "";
    return m(block("screen", vnode.attrs.modifiers || []) + selector, [
      m(Header, {
        navOpen: vnode.state.navOpen,
        onOpenMobileNavPanel: () => { vnode.state.navOpen = true; },
        onCloseMobileNavPanel: () => { vnode.state.navOpen = false; },
      }),
      m(".screen__content", vnode.children),
      !vnode.attrs.noFooter && m(Footer),
      vnode.state.navOpen && m(MobileNavPanel, {
        open: vnode.state.navOpen,
        onClose: () => { vnode.state.navOpen = false; },
      }),
    ]);
  },
};
