import {FormState} from "./FormState";
import {
  PublishedFormAction,
  PublishedFormSection,
  PublishedFormQuestion,
} from "./PublishedForm";

export const actionableQuestionTypes = ["button", "multi_button", "dropdown"];

export function actionsForSection(
  formState: FormState,
  section: PublishedFormSection,
): PublishedFormAction[] {
  return actionsForSectionWithQuestion(formState, section)
    .map(({action}) => action);
}

export function actionsForSectionWithQuestion(
  formState: FormState,
  section: PublishedFormSection,
): Array<{question: PublishedFormQuestion, action: PublishedFormAction}> {
  const actions: Array<{
    question: PublishedFormQuestion,
    action: PublishedFormAction
  }> = [];
  section.questions().forEach((question) => {
    if (actionableQuestionTypes.indexOf(question.type()) !== -1) {
      question.options().forEach((option) => {
        option.actions()
          .filter((action) => action.text().trim() !== "")
          .forEach((action) => {
            const value = formState.value(question.id()) || [];
            const isSelected =
              question.type() === "multi_button"
              ? value.indexOf(option.id()) !== -1
              : value === option.id();
            if (
              isSelected && action.whenSelected() ||
              isSelected === false && action.whenSelected() === false
            ) {
              actions.push({question, action});
            }
          });
      });
    }
  });
  return actions;
}

export function actionProgress(actions: PublishedFormAction[]): number {
  if (actions.length > 0) {
    const completed = actions.filter((action) => action.markAsDone());
    return (completed.length / actions.length) * 100;
  } else {
    return 100;
  }
}

export function actionProgressColor(progress: number) {
  if (progress === 0) {
    return "rgba(131, 130, 135, 0.5)";
  } else if (progress === 100) {
    return "#59713A";
  } else if (progress < 50) {
    return "#900304";
  } else {
    return "#E86C27";
  }
}
