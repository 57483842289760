import * as m from "mithril";

type routeName = (
  "launch" |
  "prepareForm" |
  "sectionFilter" |
  "begin" |
  "form" |
  "dashboard" |
  "returning"
);

interface Route {
  name: routeName;
  path: string;
}

interface NamedComponents {
  [name: string]: m.Component<any, any>;
}

const routes: Route[] = [
  {
    name: "launch",
    path: "/",
  },
  {
    name: "prepareForm",
    path: "/form/create",
  },
  {
    name: "sectionFilter",
    path: "/form/sections",
  },
  {
    name: "begin",
    path: "/form/begin",
  },
  {
    name: "form",
    path: "/form",
  },
  {
    name: "dashboard",
    path: "/dashboard",
  },
  {
    name: "returning",
    path: "/form/load",
  },
];

export function init(components: NamedComponents) {
  m.route.prefix = "";
  m.route(document.body, "/", buildRoutes(components));
}

export function route(name: routeName, data?: {}, options?: {}): void {
  const matchingRoute: Route =
    routes.find((checkRoute: Route) => checkRoute.name === name);
  m.route.set(matchingRoute.path, data || {}, options || {});
}

function buildRoutes(components: NamedComponents) {
  const mithrilRoutes = {};
  routes.forEach(({name, path}: Route) => {
    if (!components[name]) {
      throw new Error(`Route component ${name} not provided`);
    }
    mithrilRoutes[path] = components[name];
  });
  return mithrilRoutes;
}
