import * as m from "mithril";
import {PublicFormAPI} from "../api";
import {ErrorMessage, Screen, Section} from "../components";
import {route} from "../Router";
import * as Storage from "../Storage";

interface Attrs {}
interface State {
  error: any;
}

type Vnode = m.Vnode<Attrs, State>;

export const PrepareFormScreen: m.Component<Attrs, State> = {
  oninit: (vnode: Vnode) => {
    return PublicFormAPI.live()
      .then((publishedForm) => {
        Storage.savePublishedForm(publishedForm);
        route("sectionFilter", {}, {replace: true});
      })
      .catch((error) => vnode.state.error = error);
  },

  view: (vnode: Vnode) => {
    return m(Screen, [
      m(Section, {selector: ".align-center", contentModifiers: ["small"]}, [
        m("h1.margin-y-none", "Please wait a moment"),
        renderMessage(vnode),
      ]),
    ]);
  },
};

function renderMessage(vnode: Vnode) {
  if (vnode.state.error) {
    return m(ErrorMessage, {
      error: "Something went wrong while loading your form",
    });
  } else {
    return m("p", "While we prepare your business continuity planner");
  }
}
