import * as m from "mithril";

interface Attrs {
  percentage: number;
  color?: string;
}

type Vnode = m.Vnode<Attrs>;

const pi = 3.14159;

export const PercentageCircle: m.Component<Attrs> = {
  oninit: ({state}) => {
    state.initialising = true;
    setTimeout(() => {
      state.initialising = false;
      m.redraw();
    }, 50);
  },

  view: ({attrs: {percentage, color}, state: {initialising}}: Vnode) => {
    if (initialising) { percentage = 0; }
    const boxSize = 36;
    const circumference = 100;
    const radius = circumference / (pi * 2);
    const diameter = radius * 2;
    return m("svg.percentage-circle", {viewBox: `0 0 ${boxSize} ${boxSize}`}, [
      m("circle.percentage-circle__background-circle", {
        cx: boxSize / 2,
        cy: boxSize / 2,
        r: radius,
      }),
      m("path.percentage-circle__line", {
        style:
          `stroke-dasharray: ${percentage}, 100; ${
            color ? `stroke: ${color};` : ""
          }`,
        d:
          `M${boxSize / 2} ${(boxSize - diameter) / 2} ` +
          `a ${radius} ${radius} 0 0 1 0 ${diameter} ` +
          `a ${radius} ${radius} 0 0 1 0 ${-diameter}`,
      }),
    ]);
  },
};
