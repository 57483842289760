import * as m from "mithril";
import * as Selector from "../Selector";

type Modifier = "wide" | "inverse-primary";

interface Attrs {
  selector?: string;
  modifiers?: Modifier[];
  onClick: () => void;
}

type Vnode = m.Vnode<Attrs>;

export const Button: m.Component<Attrs> = {
  view: ({attrs: {selector= "", modifiers= [], onClick}, children}: Vnode) => {
    return m(selector + Selector.block("button", modifiers), {
      onclick: () => {
        if (onClick) { onClick(); }
      },
    }, children);
  },
};
