import * as m from "mithril";
import {block} from "../Selector";

interface Attrs {
  onClose: () => void;
}

interface State {
  expanded
}

type Vnode = m.Vnode<Attrs, State>;

export const MobileNavPanel: m.Component<Attrs, State> = {
  oninit: (vnode: Vnode) => {
    vnode.state.expanded = {};
  },

  view: (vnode: Vnode) => {
    return m(".mobile-nav-panel", [
      m(".mobile-nav-panel__row", [
        expansion(vnode, "About BLP", "about", [
          expansionLink("The BLP programme",
            "https://about.businesslinkpacific.com/about-blp/"),
          expansionLink("The finance facility",
            "https://finance.businesslinkpacific.com"),
          expansionLink("The advisor network",
            "https://about.businesslinkpacific.com" +
              "/find-a-blp-business-advisor/"),
          expansionLink("The subsidy scheme",
            "https://about.businesslinkpacific.com" +
              "/use-the-subsidy-to-access-quality-business-advice/"),
          expansionLink("Country contacts",
            "https://about.businesslinkpacific.com/countries/"),
        ]),
      ]),
      m(".mobile-nav-panel__row", [
        expansion(vnode, "SME Hub", "sme_hub", [
          expansionLink("Business adaptation grants",
            "https://finance.businesslinkpacific.com/adaptationgrants"),
          expansionLink("The business health check",
            "https://bhc.businesslinkpacific.com"),
          expansionLink("The business continuity planner",
            "https://bcp.businesslinkpacific.com"),
          expansionLink("The finance finder",
            "https://finance.businesslinkpacific.com/finder"),
        ]),
      ]),
      m(".mobile-nav-panel__row", [
        link("Find an advisor",
          "https://businesslinkpacific.com/search"),
      ]),
      m(".mobile-nav-panel__row", [
        expansion(vnode, "News & updates", "news", [
          expansionLink("News",
            "https://about.businesslinkpacific.com/news_and_updates/"),
          expansionLink("Webinars",
            "https://about.businesslinkpacific.com/news_and_updates/webinars/"),
          expansionLink("Podcasts", "https://about.businesslinkpacific.com" +
              "/news_and_updates/business-link-pacific-podcast/"),
          expansionLink("Resources", "https://about.businesslinkpacific.com" +
              "/news-and-updates/business-link-pacific-resources/"),
        ]),
      ]),
      m(".mobile-nav-panel__row", [
        expansion(vnode, "Advisor hub", "advisorHub", [
          expansionLink("Register as a business advisor",
            "https://businesslinkpacific.com/users/sign_up"),
          expansionLink("Support for business advisors",
            "https://about.businesslinkpacific.com" +
              "/support-for-business-advisors/"),
          expansionLink(
            "Sign in",
            "https://businesslinkpacific.com/users/sign_in",
            {selector: ".weight-bold"},
          ),
        ]),
      ]),
      m(".mobile-nav-panel__content", [
        m("h4.color-light-purple.margin-top-none.margin-bottom-small",
          m(".weight-bold", "GENERAL ENQUIRIES")),
        email("info@businesslinkpacific.com"),
        m("h4.color-light-purple.margin-top-medium.margin-bottom-small",
          m(".weight-bold", "COUNTRY SPECIFIC ENQUIRIES")),
        email("fiji@businesslinkpacific.com"),
        email("samoa@businesslinkpacific.com"),
        email("vanuatu@businesslinkpacific.com"),
        m("h4.color-light-purple.margin-top-medium.margin-bottom-small",
          "FOLLOW US"),
        social("Facebook", "facebook",
          "https://facebook.com/businesslinkpacific"),
        social("Twitter", "twitter",
          "https://twitter.com/businesslinkpac"),
        social("Linkedin", "linkedin",
          "https://linkedin.com/company/business-link-pacific"),
      ]),
    ]);
  },
};

function email(email: string) {
  return link(email, "mailto:" + email);
}

function expansionLink(
  label: string,
  href: string,
  options: {selector?: string} = {},
) {
  return link(label, href, {
    selector: ".mobile-nav-panel__link--expansion" + (options.selector || ""),
  });
}

function link(label: string, href: string, options: {selector?: string} = {}) {
  return m("a.mobile-nav-panel__link" + (options.selector || ""), {
    href: href,
  }, label);
}

function social(label: string, logo: string, href: string) {
  return m("a.mobile-nav-panel__link", {href: href}, [
    m("img" + block("mobile-nav-panel__social-icon"), {
      src: `/images/social-icons/${logo}-white.svg`,
    }),
    m(".mobile-nav-panel__link__text", label),
  ]);
}

function expansion(vnode, label, id, links) {
  return [
    m("a.mobile-nav-panel__link", {
      href: "#",
      onclick: () => { vnode.state.expanded[id] = !vnode.state.expanded[id]; },
    }, [
      label,
      m("img.mobile-nav-panel__expansion-button", {
        src: "/images/icons/nav-down-white.svg",
      }),
    ]),
    vnode.state.expanded[id] && m(".mobile-nav-panel__expansion", links),
  ];
}
