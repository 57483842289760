import * as m from "mithril";
import {Changeset} from "../../Changeset";

interface Attrs {
  changeset: Changeset;
  name: string;
  [key: string]: any;
}

type Vnode = m.Vnode<Attrs>;

export const Input: m.Component<Attrs> = {
  view: ({attrs: {changeset, name, ...attrs}}: Vnode) => {
    return m("input", {
      value: changeset.getValue(name),
      oninput: m.withAttr("value", (value) => changeset.change(name, value)),
      ...attrs,
    });
  },
};
