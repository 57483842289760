import * as m from "mithril";
import {Changeset} from "../Changeset";
import {Button, Form, Screen, Section} from "../components";
import {SectionSelector} from "../components/inputs/SectionSelector";
import {FormState} from "../FormState";
import {PublishedForm} from "../PublishedForm";
import {PublishedFormSection} from "../PublishedForm";
import {route} from "../Router";
import {loadFormState, loadPublishedForm} from "../Storage";

interface Attrs {}
interface State {
  changeset: Changeset;
  formState: FormState;
  sections: PublishedFormSection[];
}

type Vnode = m.Vnode<Attrs, State>;

export const SectionFilterScreen: m.Component<Attrs, State> = {
  oninit: (vnode: Vnode) => {
    vnode.state.formState = loadFormState();
    const publishedForm = loadPublishedForm();
    vnode.state.changeset =
      buildChangeset(vnode.state.formState, publishedForm);
    if (publishedForm === null) {
      vnode.state.sections = [];
    } else {
      vnode.state.sections = publishedForm.form().sections();
    }
  },

  view: ({state: {formState, changeset, sections}}: Vnode) => {
    return m(Screen, [
      m(Section, {selector: ".align-center", contentModifiers: "small"}, [
        m("h1.margin-top-none.margin-bottom-large",
          "We will review these areas of your business"),
        m(Form, {changeset}, [
          m(SectionSelector, {changeset, sections}),
        ]),
        m(".margin-top-large", [
          m(Button, {
            modifiers: "wide",
            onClick: () => { submit(formState, changeset); },
          }, "Next"),
        ]),
      ]),
    ]);
  },
};

function buildChangeset(
  formState: FormState,
  publishedForm: PublishedForm,
): Changeset {
  const values = {};
  const hasFilteredSections = formState.filteredSectionIDs().length > 0
  const allSectionIDs =
    publishedForm.form().sections().map((section) => section.id());
  (hasFilteredSections ? formState.filteredSectionIDs() : allSectionIDs)
    .forEach((sectionID) => {
      values[sectionID] = true;
    });
  return new Changeset(values);
}

function submit(formState: FormState, changeset: Changeset): void {
  const values = changeset.getValues();
  const filteredSectionIDs: number[] =
    Object.keys(values)
    .filter((sectionID: string) => values[sectionID] === true)
    .map((sectionID: string) => parseInt(sectionID));
  if (filteredSectionIDs.length > 0) {
    formState.changeFilteredSectionIDs(filteredSectionIDs);
    route("begin");
  } else {
    changeset.setResponseError("You must select at least one area");
  }
}
