import * as m from "mithril";
import {FormState} from "../FormState";
import {PublishedForm, PublishedFormSection} from "../PublishedForm";
import {block} from "../Selector";

interface Attrs {
  publishedForm: PublishedForm;
  formState: FormState;
}

type Vnode = m.Vnode<Attrs>;

export const FormSectionNavigation: m.Component<Attrs> = {
  view: ({attrs: {publishedForm, formState}}: Vnode) => {
    const sections = formState.filterSections(publishedForm.form().sections());
    return m(".form-section-navigation", [
      sections.map((section) => {
        return m(block(
          "form-section-navigation__item",
          modifiers(formState, publishedForm, section),
        ), section.name());
      }),
    ]);
  },
};

function modifiers(
  formState: FormState,
  publishedForm: PublishedForm,
  section: PublishedFormSection,
): string[] {
  const mods = [];
  const currentSection = formState.findCurrentSection(publishedForm);
  if (section.index() < currentSection.index()) {
    if (formState.sectionProgress(section) === 100) {
      mods.push("complete");
    } else {
      mods.push("incomplete");
    }
  }
  if (section.id() === currentSection.id()) { mods.push("active"); }
  return mods;
}
