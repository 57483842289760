import * as m from "mithril";
import {Screen, Section, Button} from "../components";
import {block} from "../Selector";

const forms = [
  {
    id: "business-continuity-planner",
    name: "Business Continuity Planner",
    link: "https://bcp.businesslinkpacific.com/form/create",
    icon: "bcp",
    label: "New!",
    description:
      "Check the resilience of your business to respond to a crisis like " +
      "COVID-19. BLP approved advisors can offer services aimed at " +
      "addressing the gaps identified through this tool.",
  },
  {
    id: "business-health-check",
    name: "Business Health Check",
    link: "https://bhc.businesslinkpacific.com/form/create",
    icon: "bhc",
    description:
      "Check the overall health of your business. This test is the first " +
      "step to grow your business with professional advice. BLP approved " +
      "advisors can offer services aimed at addressing the gaps identified " +
      "through this tool.",
  },
];

interface State {
  selected?: string
}

export const LaunchScreen: m.Component<{}, State> = {
  view: ({state}) => {
    return m(Screen, [
      m(Section, {modifiers: "padding-small"}, [
        m("h1.align-center.margin-bottom-none", [
          "Which business check would you like to complete?",
        ]),
      ]),
      m(block("column-container", [
        "justify-center",
        "medium-cut-off",
        "align-stretch",
      ]) + ".margin-x-small.margin-bottom-large", [
        forms.map(({id, name, link, icon, label, description}) => {
          const selector = [
            ".box.flex.flex--column.flex--align-center.padding-medium",
            ".margin-medium.align-center",
          ].join("");
          return m(selector, {
            style: "width: 100%; max-width: 420px;",
          }, [
            m(".align-right", {style: "width: 100%; min-height: 20px;"}, [
              label && m(".color-green.weight-medium", label),
            ]),
            m("img.margin-bottom-medium", {
              src: `/images/icons/${icon}.svg`,
              style: "max-width: 100px",
            }),
            m("p.weight-bold.margin-bottom-small", name),
            m("p.margin-top-small.margin-bottom-medium", description),
            m("a.margin-top-auto", {href: link, style: "width: 100%"}, [
              m(Button, {
                modifiers: ["green", "full-width"],
              }, "Select"),
            ]),
          ]);
        }),
      ]),
    ]);
  },
};
