import * as m from "mithril";
import {route} from "../Router";
import {Column} from "./Column";

interface Attrs {
  navOpen: boolean;
  onOpenMobileNavPanel: () => void;
  onCloseMobileNavPanel: () => void;
}

type Vnode = m.Vnode<Attrs>;

export const Header: m.Component<Attrs> = {
  view: (vnode: Vnode) => {
    return m(".header", [
      m(".header__row", [
        vnode.attrs.navOpen ? [
          m(".header__button.header__menu-button.header__menu-button--open", {
            onclick: () => { vnode.attrs.onCloseMobileNavPanel(); },
          })
        ] : [
          m(".header__button.header__menu-button", {
            onclick: () => { vnode.attrs.onOpenMobileNavPanel(); },
          })
        ],
        m(".header__logo-wrapper", [
          m("a.header__logo-link", {
            href: "https://businesslinkpacific.com/",
          }, [
            m("img.header__logo", {
              src: "/images/blp-logo.png",
              alt: "business link pacific logo",
            }),
          ]),
        ]),
        m(Column, {
          flex: 1,
          selector: ".header__tagline.padding-x-large",
        }, [
          m(".header__tagline", "Connect your business with quality advice"),
        ]),
        m("a.header__button.header__search-button", {
          href: "https://businesslinkpacific.com/search",
        }),
      ]),
      m(".header__row.header__row--nav", [
        m("a.header__nav-item.header__nav-item--expandable", {href: "#"}, [
          "About BLP",
          m("img.header__nav-item__dropdown-arrow", {
            src: "/images/icons/down-black.svg",
          }),
          m(".header__nav-item__dropdown", [
            m("a.header__nav-item__dropdown__item", {
              href: "https://about.businesslinkpacific.com/about-blp/",
            }, "The BLP programme"),
            m("a.header__nav-item__dropdown__item", {
              href: "https://finance.businesslinkpacific.com",
            }, "The finance facility"),
            m("a.header__nav-item__dropdown__item", {
              href: "https://about.businesslinkpacific.com" +
                "/find-a-blp-business-advisor/",
            }, "The advisor network"),
            m("a.header__nav-item__dropdown__item", {
              href: "https://about.businesslinkpacific.com" +
                "/use-the-subsidy-to-access-quality-business-advice/",
            }, "The subsidy scheme"),
            m("a.header__nav-item__dropdown__item", {
              href: "https://about.businesslinkpacific.com/countries/",
            }, "Country contacts"),
          ]),
        ]),
        m("a.header__nav-item.header__nav-item--expandable", {href: "#"}, [
          "SME Hub",
          m("img.header__nav-item__dropdown-arrow", {
            src: "/images/icons/down-black.svg",
          }),
          m(".header__nav-item__dropdown", [
            m("a.header__nav-item__dropdown__item", {
              href: "https://finance.businesslinkpacific.com/adaptationgrants",
            }, "Business adaptation grants"),
            m("a.header__nav-item__dropdown__item", {
              href: "https://bhc.businesslinkpacific.com",
            }, "The business health check"),
            m("a.header__nav-item__dropdown__item", {
              href: "https://bcp.businesslinkpacific.com",
            }, "The business continuity planner"),
            m("a.header__nav-item__dropdown__item", {
              href: "https://finance.businesslinkpacific.com/finder",
            }, "The finance finder"),
          ]),
        ]),
        m("a.header__nav-item", {
          href: "https://businesslinkpacific.com/search",
        }, "Find an advisor"),
        m("a.header__nav-item.header__nav-item--expandable", {href: "#"}, [
          "News & updates",
          m("img.header__nav-item__dropdown-arrow", {
            src: "/images/icons/down-black.svg",
          }),
          m(".header__nav-item__dropdown", [
            m("a.header__nav-item__dropdown__item", {
              href: "https://about.businesslinkpacific.com/news_and_updates/",
            }, "News"),
            m("a.header__nav-item__dropdown__item", {
              href: "https://about.businesslinkpacific.com/news_and_updates/" +
                "webinars/",
            }, "Webinars"),
            m("a.header__nav-item__dropdown__item", {
              href: "https://about.businesslinkpacific.com" +
                "/news_and_updates/business-link-pacific-podcast/",
            }, "Podcasts"),
            m("a.header__nav-item__dropdown__item", {
              href: "https://about.businesslinkpacific.com" +
                "/news-and-updates/business-link-pacific-resources/",
            }, "Resources"),
          ]),
        ]),
        m("a.header__nav-item.header__nav-item--expandable", {href: "#"}, [
          "Advisor hub",
          m("img.header__nav-item__dropdown-arrow", {
            src: "/images/icons/down-black.svg",
          }),
          m(".header__nav-item__dropdown", [
            m("a.header__nav-item__dropdown__item", {
              href: "https://businesslinkpacific.com/users/sign_up",
            }, "Register as a business advisor"),
            m("a.header__nav-item__dropdown__item", {
              href: "https://about.businesslinkpacific.com" +
                "/support-for-business-advisors/",
            }, "Support for business advisors"),
            m("a.header__nav-item__dropdown__item.weight-bold", {
              href: "https://businesslinkpacific.com/users/sign_in",
            }, "Sign in"),
          ]),
        ]),
      ]),
    ]);
  },
};
