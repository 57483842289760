import * as Router from "./Router";

import {BeginScreen} from "./screens/BeginScreen";
import {DashboardScreen} from "./screens/DashboardScreen";
import {FormScreen} from "./screens/FormScreen";
import {PrepareFormScreen} from "./screens/PrepareFormScreen";
import {ReturningScreen} from "./screens/ReturningScreen";
import {SectionFilterScreen} from "./screens/SectionFilterScreen";
import {RedirectingScreen} from "./screens/RedirectingScreen";
import {LaunchScreen} from "./screens/LaunchScreen";

import {loadFormState, loadPublishedForm} from "./Storage";

Router.init({
  launch: LaunchScreen,
  prepareForm: {
    onmatch: (args, requestedPath, route) => {
      const formState = loadFormState();
      const publishedForm = loadPublishedForm();
      if (formState && publishedForm) {
        if (formState.filteredSectionIDs().length === 0) {
          Router.route("sectionFilter");
        } else {
          Router.route("form");
        }
        return RedirectingScreen;
      } else {
        return PrepareFormScreen;
      }
    },
    render: (vnode) => vnode,
  },
  sectionFilter: {
    onmatch: (args, requestedPath, route) => {
      const formState = loadFormState();
      const publishedForm = loadPublishedForm();
      if (!formState || !publishedForm) {
        Router.route("prepareForm");
        return RedirectingScreen;
      } else {
        return SectionFilterScreen;
      }
    },
    render: (vnode) => vnode,
  },
  begin: BeginScreen,
  form: FormScreen,
  dashboard: DashboardScreen,
  returning: ReturningScreen,
});
