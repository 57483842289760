import * as m from "mithril";
import * as PublicFormSubmissionAPI from "../api/PublicFormSubmissionAPI";
import {Changeset} from "../Changeset";
import {FormState} from "../FormState";
import * as Overlay from "../Overlay";
import {PublishedForm, PublishedFormSection} from "../PublishedForm";
import {Button} from "./Button";
import {Column} from "./Column";
import {ColumnContainer} from "./ColumnContainer";
import {FormQuestionBox} from "./FormQuestionBox";
import {ProgressBar} from "./ProgressBar";
import {ProgressPercentage} from "./ProgressPercentage";

interface Attrs {
  formState: FormState;
  publishedForm: PublishedForm;
  section: PublishedFormSection;
}

interface State {
  validationErrors: {[questionID: string]: string[]};
}

type Vnode = m.Vnode<Attrs, State>;

export const FormSectionOverlay: m.Component<Attrs, State> = {
  oninit: (vnode: Vnode) => {
    vnode.state.validationErrors = {};
  },

  view: (vnode: Vnode) => {
    const {attrs: {formState, publishedForm, section}} = vnode;
    const progress = formState.sectionProgress(section);
    return m(".box.padding-x-medium.padding-y-small.input-dim", [
      m(ColumnContainer, {
        selector: ".padding-bottom-small",
        modifiers: ["align-end"],
      }, [
        m(Column, {flex: 1}, [
          m("h2.margin-y-none", section.name()),
        ]),
        m(Column, {selector: ".margin-left-small"}, [
          m(ProgressPercentage, {progress}),
        ]),
      ]),
      m(ProgressBar, {progress}),
      m(FormQuestionBox, {
        publishedForm,
        formState,
        validationErrors: vnode.state.validationErrors,
      }),
      m(".margin-top-large.margin-bottom-small.align-right", [
        m(Button, {
          onClick: () => {
            const validation = formState.validate(section);
            if (validation.valid) {
              PublicFormSubmissionAPI.update(formState);
              Overlay.close();
            } else {
              vnode.state.validationErrors = validation.errors;
            }
          },
        }, "Confirm"),
      ]),
    ]);
  },
};
