import * as m from "mithril";
import * as IndustryAPI from "../../api/IndustryAPI";
import * as LocationAPI from "../../api/LocationAPI";
import {Changeset} from "../../Changeset";
import {Industry} from "../../Industry";
import {Location} from "../../Location";
import {Constraints, validate} from "../../Validation";
import {Button} from "../Button";
import {Column} from "../Column";
import {ColumnContainer} from "../ColumnContainer";
import {Form} from "../Form";
import {FormField} from "../FormField";
import {CheckBox} from "../inputs/CheckBox";
import {NumberInput} from "../inputs/NumberInput";
import {Selector} from "../inputs/Selector";

type OnSuccessCallback = (changeset: Changeset) => void;

interface Attrs {
  changeset: Changeset;
  isSubmitting: boolean;
  onSuccess: OnSuccessCallback;
}

interface State {
  locations: Location[];
  industry: Industry[];
}

type Vnode = m.Vnode<Attrs, State>;

export const BeginBusinessDetailsForm: m.Component<Attrs, State> = {
  oninit: (vnode: Vnode) => {
    vnode.state.locations = [];
    vnode.state.industries = [];
    LocationAPI.locations().then((locations: Location[]) => {
      vnode.state.locations = locations;
      m.redraw();
    });
    IndustryAPI.industries().then((industries: Industry[]) => {
      vnode.state.industries = industries;
      m.redraw();
    });
  },

  view: (
    {
      attrs: {changeset, isSubmitting, onSuccess},
      state: {locations, industries},
    }: Vnode,
  ) => {
    return m(Form, {
      changeset,
      onSubmit: () => submit(changeset, onSuccess),
    }, [
      m(FormField, {
        name: "location_id",
        changeset,
        input: Selector,
        integerValues: true,
        nullLabel: "Country",
        options: locations.map((location: Location) => {
          return {label: location.name(), value: location.id()};
        }),
      }),
      m(FormField, {
        name: "industry_id",
        changeset,
        input: Selector,
        integerValues: true,
        nullLabel: "Industry",
        options: industries.map((industry: Industry) => {
          return {label: industry.name(), value: industry.id()};
        }),
      }),
      m(FormField, {
        name: "number_of_employees",
        changeset,
        input: NumberInput,
        placeholder: "Number of employees",
      }),
      m(FormField, {
        name: "accepted_terms_and_conditions",
        changeset,
        input: CheckBox,
        text: [
          "I agree to the ",
          m("a.color-grey", {
            href: "https://about.businesslinkpacific.com/terms/",
            target: "_blank",
          }, "terms of service"),
          " and ",
          m("a.color-grey", {
            href: "https://about.businesslinkpacific.com/privacy/",
            target: "_blank",
          }, "privacy policy"),
          " of this site",
        ],
        textUnselectable: true,
      }),
      m(".align-center.margin-top-large", [
        m(Button, {
          modifiers: ["wide"],
          onClick: () => submit(changeset, onSuccess),
        }, isSubmitting ? "Submitting..." : "Start"),
      ]),
    ]);
  },
};

const constraints: Constraints = {
  location_id: {
    notEmpty: {message: "Must select one"},
  },
  industry_id: {
    notEmpty: {message: "Must select one"},
  },
  number_of_employees: {
    number: {message: "Must be a valid number"},
  },
  accepted_terms_and_conditions: {
    true: {message: "Must accept the terms of service and privacy policy"},
  },
};

function submit(changeset: Changeset, onSuccess: OnSuccessCallback): void {
  changeset.clearErrors();
  const {valid, errors} = validate(constraints, changeset.getValues());
  if (valid === false) {
    changeset.validationErrors(errors);
  } else {
    onSuccess(changeset);
  }
}
