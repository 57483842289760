import * as m from "mithril";

import {OverlayHeader} from "./OverlayHeader";
import {Section} from "./Section";
import {Button} from "./Button";

interface Attrs {
  title: any;
  content: any;
}

export const InfoOverlay: m.Component<Attrs> = {
  view: ({attrs: {title, content}}) => {
    return m(Section, {contentModifiers: "medium"}, [
      m(".box.padding-medium", [
        m(OverlayHeader, {title}),
        content,
      ]),
    ]);
  },
};
