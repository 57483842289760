import * as m from "mithril";
import * as PublicFormSubmissionAPI from "../api/PublicFormSubmissionAPI";
import {Changeset} from "../Changeset";
import {
  Button,
  Column,
  ColumnContainer,
  FormQuestionBox,
  FormSectionNavigation,
  FormSubmit,
  ProgressBar,
  ProgressPercentage,
  Screen,
  Section,
} from "../components";
import {FormState} from "../FormState";
import {PublishedForm} from "../PublishedForm";
import {route} from "../Router";
import {block} from "../Selector";
import * as Storage from "../Storage";
import {Constraints, validate} from "../Validation";
import {log} from "../Log";
import {environment} from "../Environment";

interface Attrs {
  sectionID?: string;
}

interface State {
  formState: FormState;
  publishedForm: PublishedForm;
  changeset: Changeset;
  isSubmitting: boolean;
  validationErrors: {[questionID: number]: string[]};
}

type Vnode = m.Vnode<Attrs, State>;

export const FormScreen: m.Component<Attrs, State> = {
  oninit: (vnode: Vnode) => {
    vnode.state.publishedForm = Storage.loadPublishedForm();
    vnode.state.formState = Storage.loadFormState();
    vnode.state.formState.changeIsComplete(false);
    vnode.state.changeset = new Changeset(vnode.state.formState.details());
    vnode.state.validationErrors = {};
    vnode.state.changeset.listen((name: string, value: any) => {
      vnode.state.formState.changeDetail(name, value);
    });
    if (vnode.state.formState.filteredSectionIDs().length === 0) {
      route("sectionFilter");
    } else if (
      !vnode.state.publishedForm ||
      !vnode.state.formState.detail("accepted_terms_and_conditions")
    ) {
      route("begin");
    } else if (vnode.state.formState.hasSubmitted()) {
      route("dashboard");
    }
  },

  view: (vnode: Vnode) => {
    const {state: {publishedForm, formState}} = vnode;
    const section = formState.findCurrentSection(publishedForm);
    const progress =
      formState.isComplete() ? 100 : formState.sectionProgress(section);
    return m(Screen, [
      m(Section, {contentModifiers: "large"}, [
        m(".align-center.margin-bottom-large", [
          m("h1.margin-none", "Business Continuity Planner"),
          m("p.margin-none", "Find out how you can accelerate your business"),
        ]),
        m(ColumnContainer, [
          m(Column, {selector: ".hide-less-than-medium"}, [
            m(FormSectionNavigation, {
              publishedForm,
              formState,
            }),
          ]),
          m(Column, {flex: 1}, [
            m(".box.padding-x-medium.padding-y-small.input-dim", [
              m(ColumnContainer, {
                selector: ".padding-bottom-small",
                modifiers: ["align-end"],
              }, [
                m(Column, {flex: 1}, [
                  m("h2.margin-y-none",
                    formState.isComplete() ? "Submit" : section.name()),
                ]),
                m(Column, {selector: ".margin-left-small"}, [
                  m(ProgressPercentage, {progress}),
                ]),
              ]),
              m(ProgressBar, {progress}),
              renderContent(vnode),
              m(ColumnContainer, {
                selector: ".margin-top-large.margin-bottom-small",
                modifiers: "justify-space-between",
              }, [
                m(Column, renderSectionPreviousNavigation(vnode)),
                m(Column, renderSectionNextNavigation(vnode)),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]);
  },
};

function renderContent(vnode: Vnode) {
  const {state: {formState, publishedForm, changeset, validationErrors}} =
    vnode;
  if (formState.isComplete()) {
    return m(FormSubmit, {
      changeset,
      formState,
      onSubmit: () => { submit(vnode) },
    });
  } else {
    return m(FormQuestionBox, {
      publishedForm,
      formState,
      validationErrors,
    });
  }
}

function renderSectionPreviousNavigation(vnode: Vnode) {
  const {state: {formState, publishedForm}} = vnode;
  if (formState.currentSectionIndex(publishedForm) > 0) {
    return m(block("section-navigation-button", ["left"]), {
      onclick: () => {
        PublicFormSubmissionAPI.update(formState);
        if (formState.isComplete()) {
          formState.changeIsComplete(false);
        } else {
          formState.previousSection(publishedForm);
        }
      },
    });
  }
}

function renderSectionNextNavigation(vnode: Vnode) {
  const {state: {formState, publishedForm}} = vnode;
  const totalSections =
    formState.filterSections(publishedForm.form().sections()).length;
  if (formState.isComplete()) {
    return m(Button, {
      onClick: () => { submit(vnode); },
    }, vnode.state.isSubmitting ? "Submitting..." : "Submit");
  } else if (formState.currentSectionIndex(publishedForm) < totalSections - 1) {
    return m(block("section-navigation-button", ["right"]), {
      onclick: () => {
        const validation =
          formState.validate(formState.findCurrentSection(publishedForm));
        if (validation.valid) {
          PublicFormSubmissionAPI.update(formState);
          formState.nextSection(publishedForm);
          vnode.state.validationErrors = {};
        } else {
          vnode.state.validationErrors = validation.errors;
        }
      },
    });
  } else {
    return m(Button, {
      onClick: () => {
        const validation =
          formState.validate(formState.findCurrentSection(publishedForm));
        if (validation.valid) {
          PublicFormSubmissionAPI.update(formState);
          formState.changeIsComplete(true);
          vnode.state.validationErrors = {};
        } else {
          vnode.state.validationErrors = validation.errors;
        }
      },
    }, "Confirm");
  }
}

const constraints: Constraints = {
  first_name: {
    notEmpty: true,
  },
  last_name: {
    notEmpty: true,
  },
  role: {
    notEmpty: true,
  },
  gender: {
    notEmpty: {message: "Must select one"},
  },
  email: {
    notEmpty: true,
    email: true,
  },
  phone: {
    notEmpty: true,
  },
  company_name: {
    notEmpty: true,
  },
};

function submit(vnode: Vnode): void {
  const {state: {formState, publishedForm, changeset, isSubmitting}} = vnode;
  changeset.clearErrors();
  const {valid, errors} = validate(constraints, changeset.getValues());
  if (valid === false) {
    changeset.validationErrors(errors);
  } else if (!isSubmitting) {
    formState.changeHasSubmitted(true);
    PublicFormSubmissionAPI.update(formState)
      .then(() => {
        if (environment === "production") {
          try {
            (window as any).gtag("event", "conversion", {
              send_to: "AW-688977209/2mQMCJnLtrcBELnqw8gC",
            });
          } catch (error) {
            log("error", error);
          }
        } else {
          log("debug",
            "would send gtag conversion event but environment " +
            `(${environment}) isn't production`);
        }
        formState.changeHasSubmitted(true);
        route("dashboard");
      })
      .catch((error) => {
        vnode.state.isSubmitting = false;
        formState.changeHasSubmitted(false);
        changeset.errorResponse(error);
      });
    formState.changeHasSubmitted(false);
  }
}
