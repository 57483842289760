import * as m from "mithril";
import {FormState} from "../FormState";
import {PublishedForm} from "../PublishedForm";
import {PercentageCircle} from "./PercentageCircle";

interface Attrs {
  formState: FormState;
  publishedForm: PublishedForm;
}

type Vnode = m.Vnode<Attrs>;

export const DashboardActionProgressOverview: m.Component<Attrs> = {
  view: ({attrs: {formState, publishedForm}}: Vnode) => {
    const totalSections = publishedForm.form().sections().length;
    const completedSections = formState.filteredSectionIDs().length;
    return m(".dashboard-action-progress", [
      m(".dashboard-action-progress__circle", [
        m(PercentageCircle, {
          percentage: (completedSections / totalSections) * 100,
        }),
        m(".dashboard-action-progress__circle__text", [
          m("h1.margin-none.color-primary",
            `${completedSections}/${totalSections}`),
          m(".color-primary", "sections complete"),
        ]),
      ]),
    ]);
  },
};
