import * as m from "mithril";
import * as LocationAPI from "../api/LocationAPI";
import * as Overlay from "../Overlay";
import {chunk} from "../Chunk";
import {
  Button,
  Column,
  ColumnContainer,
  DashboardSectionCard,
  Screen,
  Section,
} from "../components";
import {AlertBanner} from "../components/AlertBanner";
import {
  DashboardActionProgressOverview,
} from "../components/DashboardActionProgressOverview";
import {DashboardSectionResults} from "../components/DashboardSectionResults";
import {FormState} from "../FormState";
import {Location} from "../Location";
import {PublishedForm, PublishedFormSection} from "../PublishedForm";
import {route} from "../Router";
import {block} from "../Selector";
import {loadFormState, loadPublishedForm} from "../Storage";
import {InfoOverlay} from "../components/InfoOverlay";
import {DiagnosticToolOverlay} from "../components/DiagnosticToolOverlay";

interface Attrs {}
interface State {
  formState: FormState;
  publishedForm: PublishedForm;
  locations: Location[];
}

type Vnode = m.Vnode<Attrs, State>;

export const DashboardScreen: m.Component<Attrs, State> = {
  oninit: (vnode: Vnode) => {
    vnode.state.formState = loadFormState();
    vnode.state.publishedForm = loadPublishedForm();
    if (!vnode.state.publishedForm) {
      route("prepareForm");
    } else {
      vnode.state.locations = [];
      return LocationAPI.locations().then((locations: Location[]) => {
        vnode.state.locations = locations;
        m.redraw();
      });
    }
  },

  view: ({
    state: {
      formState,
      publishedForm,
      locations,
    },
  }: Vnode) => {
    if (!formState || !publishedForm) {
      route("prepareForm");
      return null;
    } else if (!formState.hasSubmitted()) {
      route("form");
    }

    const sections = [
      ...formState.filterSections(publishedForm.form().sections()),
      ...formState.excludedSections(publishedForm.form().sections()),
    ];

    return m(Screen, [
      m(".dashboard-company-name-box", formState.detail("company_name")),
      m(Section, {
        selector: ".bg-white.border-bottom",
        modifiers: "padding-small",
      }, [
        m(ColumnContainer, {
          selector: ".margin-y-medium",
          modifiers: "large-cut-off",
        }, [
          m(Column, {flex: 1}, [
            m("h3.weight-bold.color-purple.align-center", [
              "Your Continuity Planner Progress",
            ]),
            m(DashboardActionProgressOverview, {formState, publishedForm}),
          ]),
          m(Column, {flex: 1}, [
            m("h3.weight-bold.color-purple", "Your Results"),
            m(DashboardSectionResults, {formState, publishedForm}),
          ]),
        ]),
      ]),
      m(Section, {
        modifiers: "padding-small",
        contentModifiers: "large",
      }, [
        m(ColumnContainer, {
          modifiers: "medium-cut-off",
        }, [
          m(Column, {flex: 1}, [
            m("h4.weight-bold.color-purple", "Improve your Score"),
            m("p", [
              "Clicking ",
              m("span.weight-bold", "Find a Local Advisor "),
              "will provide a list of quality local advisors matched to your ",
              "personal dashboard. You may be eligible for a ",
              m("span.link.link--primary", {
                onclick: () => { openSubsidyInfoOverlay(); },
              }, "subsidy"),
              " to access their services.",
            ]),
          ]),
          m(Column, {flex: 1, selector: ".padding-left-small"}, [
            m("h4.weight-bold.color-purple", "In-depth Diagnostic Tool"),
            m("p", [
              "Looking for financing or investment? ",
              "Want a deeper analysis of your business? ",
              "Find out more about the ",
              m("span.link.link--primary", {
                onclick: () => {
                  openDiagnosticToolOverlay(formState);
                },
              }, "BLP Diagnostic Tool"),
            ]),
          ]),
        ]),
      ]),
      m(".bg-purple", [
        m(".hide-more-than-medium.bg-purple.margin-y-medium", {
          style: "height: 5px",
        }),
        m(Section, {
          selector: ".hide-less-than-medium",
          modifiers: "padding-small",
          contentModifiers: "large",
        }, [
          m(ColumnContainer, {
            modifiers: ["medium-cut-off"],
          }, [
            m(StepColumn, {
              step: 1,
              icon: "clipboard-apple",
              text: "Take the Business Continuity Planner online",
            }),
            m(".vertical-line.margin-x-medium.hide-less-than-medium", {
              style: "opacity: 0.5",
            }),
            m(StepColumn, {
              step: 2,
              icon: "advisor",
              text: "Find a BLP Approved Business Advisor",
            }),
            m(".vertical-line.margin-x-medium.hide-less-than-medium", {
              style: "opacity: 0.5",
            }),
            m(StepColumn, {
              step: 3,
              icon: "subsidy-grant",
              text: "Use the Subsidy to access quality business advice",
            }),
          ]),
        ]),
      ]),
      m(Section, {
        selector: ".align-center",
        modifiers: "padding-small",
        contentModifiers: "large",
      }, [
        m("h3.color-purple", [
          "Your Plan",
        ]),
        chunk(sections, 3)
          .map((sectionsChunk: PublishedFormSection[]) => {
            return m(".dashboard-section-card-row", [
              sectionsChunk.map((section: PublishedFormSection) => {
                return m(".dashboard-section-card-container", [
                  m(DashboardSectionCard, {
                    section,
                    formState,
                    publishedForm,
                    locations,
                  }),
                ]);
              }),
            ]);
          }),
      ]),
    ]);
  },
};

interface StepColumnAttrs {
  step: number;
  icon: string;
  text: string;
}

const StepColumn: m.Component<StepColumnAttrs> = {
  view: ({attrs: {step, icon, text}}) => {
    return m(Column, {flex: 1}, [
      m(".flex.flex--align-center.margin-x-auto", [
        m("img.margin-top-extra-small", {
          style: "width: 60px",
          src: `/images/icons/${icon}-light-grey.svg`,
        }),
        m(".margin-left-medium", [
          m(".weight-bold.margin-none.color-light-grey", {
            style: "font-size: 22px",
          }, step + "."),
          m(".weight-bold.color-light-grey", text),
        ]),
      ]),
    ]);
  },
};

function openSubsidyInfoOverlay(): void {
  Overlay.open(InfoOverlay, {
    title: "Subsidy Information",
    content: [
      "If you are operating in Fiji, Papua New Guinea, Samoa, Vanuatu, ",
      "Solomon Islands or the Cook Islands you may be eligible for a BLP ",
      "subsidy to assist you with paying for services through the local BLP ",
      "advisor network. The first step is to connect with a service provider ",
      "on the BLP portal by clicking Find an Advisor.",
    ],
  });
}

function openDiagnosticToolOverlay(formState: FormState): void {
  Overlay.open(DiagnosticToolOverlay, {formState});
}
