import {publicFormID} from "../Environment";
import {PublishedFormData} from "../PublishedForm";
import {request} from "./API";

export function live(): Promise<PublishedFormData> {
  return request<PublishedFormData>(
    "get",
    `public-forms/${publicFormID}/live`,
  );
}

export function current(): Promise<PublishedFormData> {
  return request<PublishedFormData>(
    "get",
    `public-forms/${publicFormID}/current`,
  );
}
