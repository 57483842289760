import {FormStateData} from "./FormState";

export interface PublicFormSubmissionData {
  id: number;
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  role: string;
  gender: string;
  company_name: string;
  number_of_employees: number;
  location_id: number | null;
  industry_id: number | null;
  has_submitted: boolean;
  values: {[questionID: string]: any};
  filtered_section_ids: number[];
  inserted_at: string;
  updated_at: string;
}

export function publicFormSubmissionDataToFormStateData(
  submission: PublicFormSubmissionData,
): FormStateData {
  return {
    currentSectionID: null,
    values: submission.values,
    filteredSectionIDs: submission.filtered_section_ids,
    hasSubmitted: submission.has_submitted,
    isComplete: false,
    details: {
      email: submission.email,
      phone: submission.phone,
      first_name: submission.first_name,
      last_name: submission.last_name,
      role: submission.role,
      gender: submission.gender,
      company_name: submission.company_name,
      number_of_employees: submission.number_of_employees,
      location_id: submission.location_id,
      industry_id: submission.industry_id,
      sent_help_request: false,
      accepted_terms_and_conditions: true,
    },
  };
}
