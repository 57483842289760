import * as m from "mithril";
import {Changeset} from "../Changeset";
import {FormState} from "../FormState";
import * as PublicHelpRequestAPI from "../api/PublicHelpRequestAPI";
import {Constraints, validate} from "../Validation";
import * as Overlay from "../Overlay";

import {OverlayHeader} from "./OverlayHeader";
import {Form} from "./Form";
import {FormField} from "./FormField";
import {Input} from "./inputs/Input";
import {CheckBox} from "./inputs/CheckBox";
import {Section} from "./Section";
import {Button} from "./Button";

interface Attrs {
  formState: FormState;
}

interface State {
  changeset: Changeset;
}

export const DiagnosticToolOverlay: m.Component<Attrs, State> = {
  oninit: ({attrs: {formState}, state}) => {
    state.isSubmitting = false;
    state.changeset =
      new Changeset({
        email: formState.detail("email"),
        phone: formState.detail("phone"),
        accept: false,
      });
  },

  view: ({attrs: {formState}, state: {changeset}}) => {
    const submitted = formState.detail("sent_help_request");
    return m(Section, {contentModifiers: "medium"}, [
      m(".box.input-dim.padding-medium", [
        m(OverlayHeader, {title: "BLP Diagnostic Tool"}),
        m(".margin-bottom-medium", [
          "A BLP business advisor can provide a more in-depth look at your ",
          "business. The business advisor will be able to look at your ",
          "dashboard and will contact you via email to make an appointment.",
        ]),
        submitted && m(".color-primary", "Help request sent."),
        !submitted && m(HelpForm, {formState, changeset}),
      ]),
    ]);
  },
};

interface HelpFormAttrs {
  formState: FormState;
  changeset: Changeset;
}

interface HelpFormState {
  isSubmitting?: boolean;
}

const HelpForm: m.Component<HelpFormAttrs, HelpFormState> = {
  view: ({attrs: {formState, changeset}, state}) => {
    return [
      m(Form, {changeset}, [
        m(FormField, {
          name: "email",
          label: "You will be contacted on this email:",
          changeset: changeset,
          placeholder: "Email address",
          input: Input,
        }),
        m(FormField, {
          name: "phone",
          changeset: changeset,
          placeholder: "Phone Number (optional)",
          input: Input,
        }),
        m(FormField, {
          name: "accept",
          changeset: changeset,
          input: CheckBox,
          text: "I accept",
        }),
      ]),
      m(".flex.flex--justify-between.margin-top-medium", [
        m(Button, {
          modifiers: "grey",
          onClick: () => { Overlay.close(); },
        }, "Cancel"),
        m(Button, {
          onClick: () => {
            if (!state.isSubmitting) {
              submit(formState, changeset, state);
            }
          },
        }, state.isSubmitting ? "Submitting..." : "Submit")
      ]),
    ];
  },
};

const constraints: Constraints = {
  email: {
    notEmpty: {message: "Must provide an email"},
  },
  accept: {
    true: {message: "You must accept before continuing"},
  },
};

function submit(
  formState: FormState,
  changeset: Changeset,
  state: HelpFormState,
): void {
  changeset.clearErrors();
  const {valid, errors} = validate(constraints, changeset.getValues());
  if (valid === false) {
    changeset.validationErrors(errors);
  } else {
    if (!state.isSubmitting) {
      state.isSubmitting = true;
      PublicHelpRequestAPI.create(changeset.getValues() as any)
        .then(() => {
          state.isSubmitting = false;
          formState.changeDetail("sent_help_request", true);
          m.redraw();
        })
        .catch((error) => {
          state.isSubmitting = false;
          changeset.errorResponse(error);
          m.redraw();
        });
    }
  }
}
