import * as m from "mithril";
import * as Overlay from "../Overlay";

interface Attrs {
  title: string;
}

export const OverlayHeader: m.Component<Attrs> = {
  view: ({attrs: {title}}) => {
    return [
      m(".flex.flex--align-start", [
        m("h2.margin-none.flex__1", {style: "line-height: 100%"}, title),
        m("img.cursor-pointer", {
          src: "/images/icons/cross-purple.svg",
          style: "width: 16px",
          onclick: () => { Overlay.close(); },
        }),
      ]),
      m(".horizontal-line.horizontal-line--medium.bg-primary" +
        ".margin-top-small.margin-bottom-medium"),
    ];
  },
};
