import * as m from "mithril";
import * as Overlay from "../Overlay";
import * as PublicFormSubmissionAPI from "../api/PublicFormSubmissionAPI";
import {Changeset} from "../Changeset";
import {PublishedFormSection, PublishedFormQuestion} from "../PublishedForm";
import {FormState} from "../FormState";

import {OverlayHeader} from "./OverlayHeader";
import {FormField} from "./FormField";
import {QuestionInput} from "./inputs/QuestionInput";
import {Button} from "./Button";

interface Attrs {
  formState: FormState;
  section: PublishedFormSection;
  question: PublishedFormQuestion;
}

interface State {
  changeset: Changeset;
}

export const FormQuestionOverlay: m.Component<Attrs, State> = {
  oninit: ({attrs: {formState, question}, state}) => {
    state.changeset = new Changeset({
      [`${question.id()}`]: formState.value(question.id()),
    });
  },

  view: ({attrs: {formState, section, question}, state: {changeset}}) => {
    return m(".box.padding-medium.input-dim", [
      m(OverlayHeader, {title: section.name()}),
      m(FormField, {
        name: `${question.id()}`,
        changeset: changeset,
        label: question.label(),
        input: QuestionInput,
        question: question,
      }),
      m(".align-right.margin-top-large", [
        m(Button, {
          onClick: () => {
            formState.changeValue(question.id(),
              changeset.getValue(`${question.id()}`));
            formState.save();
            PublicFormSubmissionAPI.update(formState);
            Overlay.close();
          },
        }, "Confirm"),
      ]),
    ]);
  },
};
