import * as m from "mithril";
import * as PublicFormAPI from "../api/PublicFormAPI";
import * as PublicFormSubmissionAPI from "../api/PublicFormSubmissionAPI";
import {ErrorMessage, Screen, Section} from "../components";
import {FormState} from "../FormState";
import {publicFormSubmissionDataToFormStateData} from "../PublicFormSubmission";
import {route} from "../Router";
import * as Storage from "../Storage";

interface Attrs {}
interface State {
  error: any;
}

type Vnode = m.Vnode<Attrs, State>;

export const ReturningScreen: m.Component<Attrs, State> = {
  oninit: (vnode: Vnode) => {
    Storage.saveAuthToken(vnode.attrs.auth_token);
    return Promise.all([
      PublicFormSubmissionAPI.current(),
      PublicFormAPI.current(),
    ])
      .then(([submission, publishedForm]) => {
        Storage.savePublishedForm(publishedForm);
        const formState =
          new FormState(
            publicFormSubmissionDataToFormStateData(submission),
            Storage.saveFormState,
          );
        formState.save();
        route(formState.hasSubmitted() ? "dashboard" : "form");
      })
      .catch((error) => vnode.state.error = error || "error");
  },

  view: (vnode: Vnode) => {
    return m(Screen, [
      m(Section, {selector: ".align-center", contentModifiers: ["small"]}, [
        m("h1.margin-y-none", "Please wait a moment"),
        renderMessage(vnode),
      ]),
    ]);
  },
};

function renderMessage(vnode: Vnode) {
  if (vnode.state.error) {
    return m(ErrorMessage, {
      error: "Something went wrong while loading your form",
    });
  } else {
    return m("p", "While we load your business health check");
  }
}
