import * as m from "mithril";
import * as PublicFormSubmissionAPI from "../api/PublicFormSubmissionAPI";
import {Changeset} from "../Changeset";
import {
  Button,
  CheckBox,
  Column,
  ColumnContainer,
  Form,
  FormField,
  Input,
  Screen,
  Section,
  Selector,
} from "../components";
import {
  BeginBusinessDetailsForm,
} from "../components/forms/BeginBusinessDetailsForm";
import {BeginDetailsForm} from "../components/forms/BeginDetailsForm";
import {FormState} from "../FormState";
import {PublishedForm} from "../PublishedForm";
import {route} from "../Router";
import * as Storage from "../Storage";

interface Attrs {}
interface State {
  changeset: Changeset;
  formState: FormState;
  publishedForm: PublishedForm;
}

type Vnode = m.Vnode<Attrs>;

export const BeginScreen: m.Component<Attrs> = {
  oninit: (vnode: Vnode) => {
    vnode.state.formState = Storage.loadFormState();
    vnode.state.publishedForm = Storage.loadPublishedForm();
    vnode.state.changeset = new Changeset(vnode.state.formState.details());
    if (vnode.state.formState.filteredSectionIDs().length === 0) {
      route("sectionFilter");
    }
  },

  view: (vnode: Vnode) => {
    return m(Screen, [
      m(Section, {contentModifiers: ["small"]}, [
        m(".align-center.margin-bottom-large", [
          m(".section__content--small.margin-x-auto", [
            m("h1.margin-none", "Get started"),
            m("p.margin-none", [
              "Find out how to defend your business from external threats",
            ]),
          ]),
        ]),
        m(BeginBusinessDetailsForm, {
          changeset: vnode.state.changeset,
          isSubmitting: vnode.state.isSubmitting,
          onSuccess: () => { submit(vnode) },
        }),
      ]),
    ]);
  },
};

function submit(vnode: Vnode): void {
  const {state: {changeset, formState, publishedForm}} = vnode;
  if (!changeset.hasResponseError() && !vnode.state.isSubmitting) {
    formState.changeDetails(changeset.getValues() as any);
    vnode.state.isSubmitting = true;
    PublicFormSubmissionAPI.create(formState, publishedForm)
      .then(({token, public_form_submission}) => {
        vnode.state.isSubmitting = false;
        Storage.saveAuthToken(token);
        route("form");
      })
      .catch((error) => {
        changeset.errorResponse(error);
        vnode.state.isSubmitting = false;
      });
  } else {
    m.redraw();
  }
}
