import * as m from "mithril";
import {Changeset} from "../../Changeset";
import {block} from "../../Selector";

interface Attrs {
  name: string;
  changeset: Changeset;
  [key: string]: any;
}

type Vnode = m.Vnode<Attrs>;

export const DateInput: m.Component<Attrs> = {
  view: ({attrs: {name, changeset, ...attrs}}: Vnode) => {
    return m("input", {
      type: "date",
      value: changeset.getValue(name),
      oninput: m.withAttr("value", (value) => {
        const date = new Date(value);
        if (!isNaN(date.getTime())) {
          changeset.change(name, date.toISOString().substring(0, 10));
        }
      }),
      ...attrs,
    });
  },
};
