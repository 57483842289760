import * as m from "mithril";
import {
  actionProgress,
  actionProgressColor,
  actionsForSection,
} from "../Actions";
import {FormState} from "../FormState";
import {
  PublishedForm,
  PublishedFormAction,
  PublishedFormSection,
} from "../PublishedForm";
import {block} from "../Selector";
import {ProgressPercentage} from "./ProgressPercentage";

interface Attrs {
  formState: FormState;
  publishedForm: PublishedForm;
}

type Vnode = m.Vnode<Attrs>;

export const DashboardSectionResults: m.Component<Attrs> = {
  view: ({attrs: {formState, publishedForm}}: Vnode) => {
    const sections: PublishedFormSection[] =
      publishedForm.form().sections().sort((a, b) => {
        return sectionActionProgress(formState, b) -
          sectionActionProgress(formState, a);
      });
    return m(".dashboard-section-results", sections.map((section) => {
      const isActive =
        formState.filteredSectionIDs().indexOf(section.id()) !== -1;
      const actions = actionsForSection(formState, section);
      const progress = isActive ? actionProgress(actions) : 0;
      const color = actionProgressColor(progress);
      return m(".dashboard-section-results__row", [
        m("h4.dashboard-section-results__row__name", {
          style: !isActive ? `color: ${color};` : null,
        }, section.name()),
        m(".dashboard-section-results__row__progress-bar", [
          isActive &&
            m(".dashboard-section-results__row__progress-bar__progress", {
              style: `width: ${progress}%; background-color: ${color};`,
            }),
        ]),
        m(".dashboard-section-results__row__percentage", {
          style: `color: ${color};`,
        }, [
          isActive ? m(ProgressPercentage, {progress, color}) : "N/A",
        ]),
      ]);
    }));
  },
};

function sectionActionProgress(
  formState: FormState,
  section: PublishedFormSection,
): number {
  const isActive = formState.filteredSectionIDs().indexOf(section.id()) !== -1;
  const actions = actionsForSection(formState, section);
  return isActive ? actionProgress(actions) : 0;
}
