import * as m from "mithril";
import {Changeset} from "../Changeset";
import {Column} from "./Column";
import {ColumnContainer} from "./ColumnContainer";
import {Form} from "./Form";
import {FormField} from "./FormField";
import {Input} from "./inputs/Input";
import {Selector} from "./inputs/Selector";

type OnSubmit = (changeset: Changeset) => void;

interface Attrs {
  changeset: Changeset;
  onSubmit: OnSubmit;
}

type Vnode = m.Vnode<Attrs>;

export const FormSubmit: m.Component<Attrs> = {
  view: ({attrs: {changeset, onSubmit}}: Vnode) => {
    return [
      m("p", "Please check your answers and email address before you submit."),
      m("p", [
        "Once submitted you will be emailed a link so that you can return ",
        "to your dashboard at any time.",
      ]),
      m(Form, {changeset, onSubmit}, [
        m(ColumnContainer, {modifiers: "small-cut-off"}, [
          m(Column, {selector: ".padding-right-medium", flex: 1}, [
            m(FormField, {
              name: "first_name",
              changeset,
              input: Input,
              placeholder: "First name",
            }),
          ]),
          m(Column, {selector: ".padding-left-medium", flex: 1}, [
            m(FormField, {
              name: "last_name",
              changeset,
              input: Input,
              placeholder: "Last name",
            }),
          ]),
        ]),
        m(FormField, {
          name: "role",
          changeset,
          input: Input,
          placeholder: "Role",
        }),
        m(FormField, {
          name: "gender",
          changeset,
          input: Selector,
          nullLabel: "Gender *",
          options: [
            {label: "Male", value: "male"},
            {label: "Female", value: "female"},
            {label: "Other", value: "other"},
          ],
        }),
        m(FormField, {
          name: "email",
          changeset,
          input: Input,
          placeholder: "Email address",
        }),
        m(FormField, {
          name: "phone",
          changeset,
          input: Input,
          placeholder: "Phone Number",
        }),
        m(FormField, {
          name: "company_name",
          changeset,
          input: Input,
          placeholder: "Business name",
        }),
        m("p.color-grey", [
          "* Gender information helps us assess the impact of this tool",
        ]),
      ]),
    ];
  },
};
